<template functional>
  <div
    v-if="props.errors.length > 0"
    :ref="data.ref"
    :class="[
      'errors px-3 py-1 text-xs text-white bg-red-500',
      data.class,
      data.staticClass,
    ]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <span class="block text-center error">{{ props.errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "BaseError",
  props: {
    errors: {
      type: [Array, Object],
      default: () => [],
    },
  },
};
</script>
