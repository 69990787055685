


































import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted,
} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";

import { BaseRadioButtonProps } from "../BaseRadioButton";

export default defineComponent({
  name: "BaseRadioGroup",
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<BaseRadioButtonProps[]>,
      required: true,
    },
    labelStyles: {
      type: String,
    },
    radioButtonWrapperStyles: {
      type: String,
    },
    radioButtonStyles: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
  },
  components: {
    ValidationProvider,
  },
  setup(props, ctx) {
    /* eslint-disable  */
    const providerRef = ref();
    /* eslint-enable  */

    const computedOptions = computed<BaseRadioButtonProps[]>(() => {
      const options: BaseRadioButtonProps[] = [...props.items];

      options.forEach((option) => {
        option["checked"] = props.value == option.value;
      });

      return options;
    });

    async function handleInput(value: string) {
      const { valid } = await providerRef.value.validate(event);

      if (valid) {
        ctx.emit("input", value);
      } else {
        ctx.emit("input", "");
      }
    }

    onMounted(() => {
      providerRef.value.value = props.value;
    });

    return {
      providerRef,
      options: computedOptions,
      handleInput,
    };
  },
});
