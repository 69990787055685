import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import VModal from "vue-js-modal";

import { upperFirst, camelCase } from "lodash-es";

export const modalDefaults = {
  scrollable: true,
  height: "auto",
  adaptive: true,
  reset: true,
  maxWidth: 1000,
  width: "100%",
  focusTrap: true,
};

Vue.config.productionTip = false;

Vue.use(VueCompositionApi);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: modalDefaults,
});

const requireComponent = require.context(
  // The relative path of the components folder
  "../shared/components/base",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      /* eslint-disable-next-line */
      fileName
        .split("/")
        .pop()!
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

export default Vue;
