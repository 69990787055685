import { computed } from "@vue/composition-api";
import { isEmpty, kebabCase } from "lodash-es";
import { nanoid } from "nanoid";

interface Props {
  title?: string;
  rules?: string | Record<string, unknown>;
  name?: string;
  vid?: string;
}

export function useValidationHelper({
  title = "",
  rules = "",
  name = "",
  vid = "",
}: Props) {
  const transformedTitle = computed(() => kebabCase(title));
  const transformedName = computed(() => kebabCase(name));

  const hasRules = computed(() => !isEmpty(rules));
  const hasVid = computed(() => !isEmpty(vid));
  const isRequired = computed(
    () => hasRules.value && rules?.toString().indexOf("required") > -1
  );

  const generatedVid = computed(() => {
    if (hasVid.value) {
      return vid;
    }

    if (!isRequired.value) {
      return nanoid();
    }

    return transformedName.value || transformedTitle.value;
  });

  return {
    transformedTitle,
    transformedName,
    generatedVid,
  };
}
