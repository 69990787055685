





















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseRadioButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
  },
});
