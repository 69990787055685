
























































import { defineComponent, PropType } from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";

import { useValidationHelper } from "@/hooks/useValidationHelper.hook";

export default defineComponent({
  name: "BaseInputField",
  props: {
    title: String,
    value: {
      type: [String, Number],
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: "text",
    },
    loading: {
      type: Boolean,
      defaullt: false,
    },
    customErrors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    infos: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    inputStyles: {
      type: String,
    },
  },
  components: {
    ValidationProvider,
  },
  setup(props) {
    const { generatedVid } = useValidationHelper({
      title: props.title,
      rules: props.rules,
      name: props.name,
      vid: props.vid,
    });

    return {
      generatedVid,
    };
  },
});
