var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"block"},[_c('BaseIconSlotWrapper',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_vm._t("icon")]},proxy:true}],null,true)},[(_vm.title)?_c('BaseLabel',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c('ValidationProvider',{ref:"providerRef",attrs:{"rules":_vm.rules,"name":_vm.name,"vid":_vm.generatedVid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:{
        'focus-within:shadow-outline-red':
          errors.length > 0 || _vm.customErrors.length > 0,
        'cursor-not-allowed': _vm.$attrs.disabled,
      }},[_c('div',{staticClass:"relative z-10"},[_c('input',_vm._b({staticClass:"block w-full mt-1 text-black form-input",class:[
            {
              'border-red-500 focus:shadow-none':
                errors.length > 0 || _vm.customErrors.length > 0,
              'border-orange-500 focus:border-current focus:shadow-none':
                _vm.infos.length > 0,
            },
            _vm.inputStyles ],attrs:{"type":_vm.type,"disabled":_vm.$attrs.disabled || _vm.loading},domProps:{"value":_vm.value},on:{"v-on":_vm.$listeners,"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event.target.value)}}},'input',[_vm.$attrs, _vm.$listeners],false)),_vm._t("default")],2),_c('BaseError',{attrs:{"errors":_vm.customErrors.concat( errors)}}),_c('BaseInfo',{attrs:{"infos":_vm.infos}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }