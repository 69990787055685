
      export default {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"32","height":"32","viewBox":"0 0 32 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M16.25 29C23.2916 29 29 23.2916 29 16.25C29 9.20837 23.2916 3.5 16.25 3.5C9.20837 3.5 3.5 9.20837 3.5 16.25C3.5 23.2916 9.20837 29 16.25 29Z","stroke":"#FF8956","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12 12.425C12.2347 12.425 12.425 12.6153 12.425 12.85C12.425 13.0848 12.2347 13.275 12 13.275C11.7652 13.275 11.575 13.0848 11.575 12.85C11.575 12.6153 11.7652 12.425 12 12.425","stroke":"#FF8956","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M20.5 12.425C20.7347 12.425 20.925 12.6153 20.925 12.85C20.925 13.0848 20.7347 13.275 20.5 13.275C20.2652 13.275 20.075 13.0848 20.075 12.85C20.075 12.6153 20.2652 12.425 20.5 12.425","stroke":"#FF8956","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M21.2168 20.4847C18.857 20.2935 16.575 21.2048 15 22.8952","stroke":"#FF8956","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])
          )
        }
      }
    