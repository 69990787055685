







































import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      requred: false,
    },
    value: {
      type: [String, Number, Boolean],
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    additionalTitleClasses: {
      type: String,
      default: "",
    },
  },
  components: {
    ValidationProvider,
  },
  setup(props, ctx) {
    const providerRef = ref();
    const checkboxValue = ref(props.value);

    function emitValue() {
      ctx.emit("input", checkboxValue.value);
    }

    onMounted(() => {
      providerRef.value.value = props.value;
    });

    return {
      checkboxValue,
      emitValue,
      providerRef,
    };
  },
});
