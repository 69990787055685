<template functional>
  <Component
    :is="require(`@/assets/svgs/${props.name}.svg?inline`).default"
    :ref="data.ref"
    :class="[data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  />
</template>

<script>
export default {
  name: "BaseIcon",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
