














import { defineComponent, computed } from "@vue/composition-api";

import { BtnTypes } from "./BaseButton.types";

export default defineComponent({
  name: "BaseButton",
  props: {
    title: {
      type: String,
    },
    btnType: {
      type: String,
      default: "primary",
      validator: (value: string) => Object.keys(BtnTypes).indexOf(value) !== -1,
    },
    large: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const buttonClasses = computed(() => [
      "btn",
      BtnTypes[props.btnType as keyof typeof BtnTypes],
      {
        "btn-large": props.large,
        "opacity-50 cursor-not-allowed": props.disabled,
      },
    ]);

    const componentType = computed(() => {
      if (props.href) {
        return "a";
      } else {
        return "button";
      }
    });

    return {
      buttonClasses,
      componentType,
    };
  },
});
