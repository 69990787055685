<template functional>
  <span
    :ref="data.ref"
    :class="['text-base md:text-lg', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "BaseLabel",
};
</script>
