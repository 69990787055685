

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseInfo",
  props: {
    infos: {
      type: Array,
      default: () => [],
    },
  },
});
