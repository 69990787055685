


















import { defineComponent, reactive } from "@vue/composition-api";

import { modalDefaults } from "@/helper/initVue";

export default defineComponent({
  name: "BaseModal",
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const modalOptions = reactive({
      ...modalDefaults,
      ...props.options,
    });

    function close() {
      ctx.root.$modal.hide(props.name);
    }

    return {
      close,
      modalOptions,
    };
  },
});
