
























import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "BaseToggle",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      requred: false,
    },
    value: {
      type: [String, Number],
    },
    name: {
      type: String,
      default: "",
    },
  },
  components: {},
  setup(props, ctx) {
    const toggleValue = ref(!!props.value);

    function emitValue() {
      const value = toggleValue.value ? "X" : "";
      ctx.emit("update-toggle-value", value);
    }

    // Update Toggle Value if props change
    watch(
      () => props.value,
      () => {
        toggleValue.value = !!props.value;
      }
    );

    return {
      toggleValue,
      emitValue,
    };
  },
});
