<template functional>
  <fieldset
    :class="[
      { 'opacity-50 select-none': props.disabled },
      props.disabled && props.disabledClasses,
      data.class,
      data.staticClass,
    ]"
    :disabled="props.disabled"
  >
    <slot />
  </fieldset>
</template>

<script>
export default {
  name: "BaseFieldset",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledClasses: {
      type: String,
      default: "",
    },
  },
};
</script>
