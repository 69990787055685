




































import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  onMounted,
} from "@vue/composition-api";
import { head, isEmpty, split, toString } from "lodash-es";
import { mask } from "vue-the-mask";

import {
  parseISO,
  isDate,
  isValid,
  isEqual,
  isBefore,
  isAfter,
  formatISO,
} from "date-fns";

/*! purgecss start ignore */
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
/*! purgecss end ignore */

interface DatePickerState {
  inputValue: string;
  datePickerValue: string | Date | null;
  currentDate: Date | null;
  rangeErrors: string[];
  info: string[];
  skipValidationOnce: boolean;
}

export default defineComponent({
  name: "BaseDatePicker",
  directives: {
    mask,
  },
  props: {
    title: String,
    value: {
      type: [String, Number],
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    showInput: {
      type: Boolean,
      default: true,
    },
    initialValue: {
      type: [Date, String],
      default: null,
    },
    minDateError: {
      type: String,
      default:
        "Achtung! Das eingegebene Datum liegt zu weit in der Vergangenheit.",
    },
    maxDateError: {
      type: String,
      default: "Achtung! Das eingegebene Datum liegt zu weit in der Zukunft.",
    },
    showUnderageError: {
      type: Boolean,
      default: true,
    },
    updateDateForRangeError: {
      type: Boolean,
      default: true,
    },
    visibleYears: {
      type: Number,
      default: 10,
    },
    customInfo: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueDatePicker,
  },
  setup(props, ctx) {
    const datepickerConfig = reactive({
      locale: { lang: "de", weekDays: ["M", "D", "M", "D", "F", "S", "S"] },
      format: "YYYY-MM-DD",
      formatHeader: "dd, DD. MMMM",
      visibleYearsNumber: props.visibleYears,
      name: props.title,
    });

    const state = reactive<DatePickerState>({
      inputValue: "",
      datePickerValue: null,
      currentDate: null,
      rangeErrors: [],
      info: [],
      skipValidationOnce: false,
    });

    const iconColor = ctx.attrs.disabled ? "text-gray-200" : "text-orange-500";

    const allInfos = computed(() => [...props.customInfo, ...state.info]);

    const rawInputDateString = computed(() => {
      if (!state.inputValue) {
        return "";
      }

      const dateString = state.inputValue
        .replace(/ /gi, "")
        .split("/")
        .reverse()
        .join("-");

      return dateString;
    });

    const isValidYear = computed(
      () => toString(head(split(rawInputDateString.value, "-", 3))).length === 4
    );

    const isValidInputDate = computed(() => {
      if (isEmpty(rawInputDateString.value)) {
        return true;
      }

      const date = new Date(rawInputDateString.value);

      return isValid(date) && isValidYear.value;
    });

    const parsedInputDateString = computed(() => {
      if (rawInputDateString.value.length !== 10) {
        return null;
      }

      try {
        return parseISO(rawInputDateString.value);
      } catch (error) {
        return null;
      }
    });

    const parsedDatePickerDate = computed(() => {
      if (typeof state.datePickerValue === "string") {
        const parsedDate = parseISO(state.datePickerValue);

        if (isValid(parsedDate)) {
          return parsedDate;
        }
      } else if (isValid(isDate(state.datePickerValue))) {
        return state.datePickerValue;
      }

      return null;
    });

    const inputValueFromDatePicker = computed(() => {
      if (typeof state.datePickerValue === "string") {
        return state.datePickerValue.split("-").reverse().join(" / ");
      }

      return "";
    });

    watch(parsedInputDateString, (currentDate, prevDate) => {
      if (
        currentDate === prevDate ||
        (parsedInputDateString.value &&
          parsedDatePickerDate.value &&
          isEqual(parsedInputDateString.value, parsedDatePickerDate.value))
      ) {
        validateInput();
        return;
      }

      state.info = [];
      state.datePickerValue = parsedInputDateString.value;
    });

    watch(inputValueFromDatePicker, (currentDate, prevDate) => {
      if (currentDate === prevDate) {
        return;
      }

      state.inputValue = inputValueFromDatePicker.value;
    });

    watch(
      () => state.inputValue,
      (currentValue, prevValue) => {
        if (currentValue === prevValue) {
          return;
        }
        ctx.emit("input", rawInputDateString);
      }
    );

    function validateInput() {
      if (state.skipValidationOnce) {
        state.skipValidationOnce = false;
        return;
      }

      state.info = [];
      state.rangeErrors = [];

      if (!isValidInputDate.value) {
        state.rangeErrors.push("Bitte geben Sie ein korrektes Datum ein");
        state.inputValue = "";
        return;
      }

      const minDate = (ctx.attrs.minDate as any) as Date;
      const maxDate = (ctx.attrs.maxDate as any) as Date;

      const inputsEqual = isEqual(
        parsedInputDateString.value as Date,
        (parsedDatePickerDate.value as Date) || (state.datePickerValue as Date)
      );

      if (!inputsEqual) {
        return;
      }

      let updatedDatepickerValue = null;

      const isBeforeMinDate = minDate
        ? isBefore(parsedInputDateString.value as Date, minDate)
        : false;
      if (isBeforeMinDate) {
        if (props.updateDateForRangeError) {
          updatedDatepickerValue = formatISO(new Date(minDate), {
            representation: "date",
          });

          state.info.unshift(
            `${props.minDateError} Es wurde das nächstmögliche Datum eingetragen.`
          );
          state.skipValidationOnce = true;
        } else {
          state.rangeErrors.push(props.minDateError);
        }
      }

      const isAfterMaxDate = maxDate
        ? isAfter(parsedInputDateString.value as Date, maxDate)
        : false;
      if (isAfterMaxDate) {
        if (props.updateDateForRangeError) {
          updatedDatepickerValue = formatISO(new Date(maxDate), {
            representation: "date",
          });

          state.info.unshift(
            `${props.maxDateError} Es wurde das nächstmögliche Datum eingetragen.`
          );
          state.skipValidationOnce = true;
        } else {
          if (
            props.showUnderageError &&
            !isAfter(parsedInputDateString.value as Date, new Date())
          ) {
            state.rangeErrors.unshift("Die angegebene Person ist minderjährig");
          } else {
            state.rangeErrors.unshift(props.maxDateError);
          }
        }
      }

      if (updatedDatepickerValue) {
        state.datePickerValue = updatedDatepickerValue;
      }

      ctx.emit("update:rangeErrors", state.rangeErrors);
    }

    function setInitialDatepickerValue() {
      state.datePickerValue =
        props.initialValue || (props.value as any) || null;
    }

    onMounted(() => {
      setInitialDatepickerValue();
    });

    watch(
      () => props.initialValue,
      (currentValue, prevValue) => {
        if (currentValue === prevValue) {
          return;
        }

        setInitialDatepickerValue();
      }
    );

    return {
      isValidInputDate,
      datepickerConfig,
      rawInputDateString,
      parsedInputDateString,
      parsedDatePickerDate,
      iconColor,
      inputValueFromDatePicker,
      ...toRefs(state),
      validateInput,
      allInfos,
    };
  },
});
